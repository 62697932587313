import './App.css';
import DiscordVerify from './DiscordVerify';
import ActualLandingPage from './stages/ActualLandingPage';
import LandingPage from './stages/LandingPage';
import Terminal from './stages/Terminal';
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path='/.well-known/discord' element={<DiscordVerify />} />
      <Route path='/actuallandingpage' element={<ActualLandingPage />} />
    </Routes>
  );
}

export default App;
