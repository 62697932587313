import React, { useState, useEffect, useRef } from 'react';
import './Terminal.css';
import { Link } from 'react-router-dom';

const Terminal = ({ onTransition }) => {
    const directory = 'C:\\Katsimpas\\Konstantinos> ';
    const [text, setText] = useState(directory)
    const [message, setMessage] = useState('');
    const [count, setCount] = useState(0);
    const command = 'start portfolio';
    const commandDelay = 10;

    var messagesDelay = {
        30: "Starting building website",
        34: "├─ Preparing project structure",
        35: "│ ├─ Initializing dependencies",
        40: "│ ├─ Setting up environment",
        41: "│ ├─ Compiling portfolio",
        43: "│ ├─ Loading assets",
        50: "│ ├─ Checking configurations",
        51: "│ ├─ Optimizing images",
        53: "│ ├─ Configuring routes",
        54: "│ └─ Performing final checks",
        56: "├─ Building",
        63: "│ └─Starting server",
        78: "└─ Done! 🚀",
        90: "└─ Done! 🚀",
        96: "└─ Done! 🚀",
        97: "└─ Done! 🚀",
        99: "└─ Done! 🚀",
        100: "└─ Done! 🚀",
        101: "└─ Done! 🚀",
        102: "└─ Done! 🚀",
        103: "└─ Done! 🚀"
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (count < 300) setCount(count + 1);
            updateText();
            if (count >= 160) document.getElementById('link').click();
        }, 50);
    }, [count]);

    function updateText() {
        if (count < command.length + commandDelay) {
            setText(prev => prev + command.charAt(count - commandDelay));
        } else if (count in messagesDelay) {
            setMessage(prev => prev + "\n" + messagesDelay[count]);
        }
    }

    return (
        <div>
            <Link to="/actuallandingpage" id='link' />
            <svg className='noise'>
                <defs>
                    <filter id='noise'>
                        <feTurbulence baseFrequency='0.5' numOctaves='2' result='static' seed='0'>
                            <animate attributeName='seed' values='0;100' dur='1s' repeatCount='indefinite' />
                        </feTurbulence>
                        <feDisplacementMap in='SourceGraphic' in2='static' scale='0'>
                            <animate attributeName='scale' values='0;70;' dur='8s' repeatCount='1'
                                keyTimes='0;1' calcMode='spline'
                                keySplines='0.7 0 0.3 0.7' begin='1' />
                        </feDisplacementMap>
                    </filter>
                </defs>
            </svg>
            <div className="terminal-window" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                <div className="terminal-header">
                    <span className="dot red"></span>
                    <span className="dot yellow"></span>
                    <span className="dot green"></span>
                    <span className='title'>bash</span>
                </div>
                <div className="terminal-body">
                    <p>{text}</p>
                    <pre>{message}</pre>
                </div>
            </div>
        </div>
    );
};

export default Terminal;
