import Terminal from './Terminal';
import './LandingPage.css';
import { useState } from 'react';

export default function LandingPage() {
    var url = "https://paveldogreat.github.io/WebGL-Fluid-Simulation/";
    const [seeIFrame, setSeeIFrame] = useState(false);

    setTimeout(() => {
        setSeeIFrame(true);
    }, 4000);

    return (
        <div className='landingpage'>
            <div className='darkerDiv'>
            </div>
            {seeIFrame ?
                <div>
                    <div className="blur-layer"></div>
                    <iframe className='iframe' id="fluid-iframe" title="landingpage" src={url} width="100%" height="100%" style={{ border: 'none' }}></iframe>
                </div>
                : null}
            <div className="terminal" > <Terminal /> </div>

        </div>
    )
}
