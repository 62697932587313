import './ActualLandingPage.css';
import { motion as m } from 'framer-motion';


export default function ActualLandingPage() {
    var url = "https://paveldogreat.github.io/WebGL-Fluid-Simulation/";

    return (
        <div className='actuallandingpage' >
            <m.h1 className='test' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.8 }}>Actual Landing Page</m.h1>
        </div>
    )
}
